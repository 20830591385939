<template>
  <div>
    <h3 class="py-3">
      Filter by :
    </h3>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="form.label"
          outlined
          :label="$t('filter.label')"
          required
          dense
          @input="doFilter()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: (vm) => ({
      codes: [],
      form: {
        label: null,
      },
    }),
    created () {
      this.fetchAllProducts()
    },
    methods: {
      doFilter () {
        this.$emit('doFilter', this.form)
      },
    },
  }
</script>
