<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <filterComponents
          @doFilter="setFilter"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        {{ $t('transfer.transfer') }}
        <v-spacer />
        <router-link
          :to="{ path: '/transfer/transferForm'}"
          color="primary"
        >
          <v-btn
            outlined
            class="mx-2"
            color="primary"
          >
            {{ $t('transfer.makeNewTransfer') }}
          </v-btn>
        </router-link>
        <v-btn
          v-if="this.$route.name === 'transferReport'"
          outlined
          class="mx-2"
          color="primary"
          @click="exportExel()"
        >
          {{ $t('actions.print') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="dataLoading"
        :headers="headers"
        :search="search"
        :items="list"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
        @fetchAllItems="fetchAllItems"
      >
        <template v-slot:[`item.fromTo`]="{ item }">
          {{ item.from_branch.name }}
          <v-icon>
            mdi-share
          </v-icon>
          {{ item.to_branch.name }}
        </template>

        <template
          v-slot:no-data
          loading
        >
          <p>{{ $t('actions.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import filterComponents from './filter.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const transferServices = ServiceFactory.get('transfer')
  export default {
    name: 'Transfer',
    components: {
      filterComponents,
    },
    data: (vm) => ({
      search: '',
      dataLoading: false,
      page: 0,
      total: 0,
      numberOfPages: 0,
      path: '',
      options: {},
      list: [],
      Roles: [],
      filter: {
        label: null,
      },
      loading: false,
      deleteDailog: false,
      itemDetails: {},
      editedIndex: -1,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      disabled: false,
      header: ['PRODUCT CODE', 'FROM/TO', 'DATE', 'SELLING PRICE', 'REQUEST BY'],
      headers: [
        {
          text: vm.$t('transfer.id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: vm.$t('transfer.product'), sortable: false, value: 'product.label' },
        { text: vm.$t('transfer.fromTo'), sortable: false, value: 'fromTo' },
        { text: vm.$t('transfer.salePrice'), sortable: false, value: 'product.item_total_after_profit' },
        { text: vm.$t('transfer.date'), sortable: false, value: 'created' },
        { text: vm.$t('transfer.requestBy'), sortable: false, value: 'employee.name' },
        { text: vm.$t('transfer.img'), sortable: false, value: 'img' },
      ],
      bookType: 'xlsx',
      autoWidth: true,
      filename: 'Transfer Report',
    }),
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
    },
    methods: {
      setFilter (value) {
        this.filter = value
        this.fetchAllItems()
      },
      async fetchAllItems () {
        this.dataLoading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const list = await transferServices.getAllItems(itemsPerPage, page, pageNumber, this.filter)
        console.log('list', list)
        this.list = list.data.data
        this.total = list.data.total
        this.path = list.path
        this.dataLoading = false
      },
      async exportExel () {
        this.loading = true
        const listData = await transferServices.getAllItems('all', 1)
        import('@/vendor/Export2Excel').then(excel => {
        const tHeader = this.header
        const list = listData.data.data.map(item => {
          return {
            code: item.product.label,
            fromTo: item.from_branch.name + '=>' + item.to_branch.name,
            total: item.product.item_total_after_profit,
            created: item.created,
            employee: item.employee.name,
            }
        })
        const data = this.formatJson(list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        })
        this.loading = false
      })
      },
      formatJson (jsonData) {
        return jsonData.map(v => {
          return Object.values(v)
        })
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
